














































import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import PayeeDetailsComponent from '../components/PayeeDetailsComponent.vue';
import PayeeComponent from '../components/PayeeComponent.vue';
import DiversityInfoComponent from '../components/DiversityInfoComponent.vue';
import DialogComponent from '../components/DialogComponent.vue';
import ErrorComponent from '../components/ErrorComponent.vue';
import { handleError } from '../lib/shared';
import * as features from '../lib/feature-constants';

@Component({
  components: {
    ErrorComponent,
    PayeeDetailsComponent,
    PayeeComponent,
    DiversityInfoComponent,
    DialogComponent,
  },
})
export default class PayeeSummary extends BaseComponent {

  protected baseRoute: string = '';
  protected feature: string = '';
  private arrErrors: Error[] = [];
  private editVendor: boolean = false;
  private editDiversityInfo: boolean = false;
  private updateVendorKey: number = 0;
  private editedId: string = '';

  private clientBaseRoute: string = '/clients';
  private vendorBaseRoute: string = '/payees';

  protected mounted() {
    this.mount();
  }

  private mount() {
    this.editedId = this.$route.params.id;
    this.baseRoute = this.getBaseRoute();
    this.feature = this.getVendorType() === 'client' ? features.clientMenu : '';
  }

  private closeEditVendor() {
    this.editVendor = false;
  }

  private closeEditDiversityInfo() {
    this.editDiversityInfo = false;
  }

  private updateVendor() {
    this.updateVendorKey++;
  }

  private getBaseRoute(): string {
    return this.$route.path.startsWith(this.clientBaseRoute) ? this.clientBaseRoute : this.vendorBaseRoute;
  }

  private getVendorType(): string {
    return this.$route.path.startsWith(this.clientBaseRoute) ? 'client' : 'subvendor';
  }

  private getVendorTypeDescription(): string {
    return this.$route.path.startsWith(this.clientBaseRoute) ? 'Client' : 'Payee';
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }
}
